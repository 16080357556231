import { cookiesConstants, getCookie, setCookie } from './cookies';
var getSearchExperimentId = function getSearchExperimentId() {
  return getCookie(cookiesConstants.EXPERIMENT_TID);
};
var setSearchExperimentId = function setSearchExperimentId(domain, searchExperimentId) {
  var persistedSearchExperimentId = getSearchExperimentId();
  if (!persistedSearchExperimentId) {
    setCookie({
      domain: domain,
      name: cookiesConstants.EXPERIMENT_TID,
      value: searchExperimentId
    });
  }
  return searchExperimentId;
};
export default {
  getSearchExperimentId: getSearchExperimentId,
  setSearchExperimentId: setSearchExperimentId
};